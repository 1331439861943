import React from "react";

import Contact from "../components/contact";
import Footer from "../components/Footer";
import SweetBoyTitle from "../components/sweetBoyTitle";
import SweetBoyPicture from "../components/sweetBoyPicture";
import ClimbingBoy from "../components/climbingBoy";
import Layout from "../components/Layout";
import Landing from "../components/Landing";
import Info from "../components/Info";
import SmokeFusion from "../components/SmokeFusion";
import About from "../components/About";
import AstroFlap from "../components/AstroFlap";
import { Helmet } from "react-helmet";

function IndexPage() {

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>MoveArc</title>
        <meta
          name="description"
          content="Movearc - game system based on interactive screen with wristband rader."
        />
      </Helmet>
      <Landing />
      <About />
      <Info />
      <ClimbingBoy />
      <SmokeFusion />
      <AstroFlap />
      <SweetBoyTitle />
      <SweetBoyPicture />
      <Contact />
      <Footer />
    </Layout>
  );
}
export default IndexPage;
