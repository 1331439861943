import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import {
  AnimatedElements,
  Computer,
  Laptop,
  Badge,
  ComputerBefore,
  ComputerAfter,
} from "./styled";

const animatedImage = () => {
  const [isVisible, setVisible] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
    <AnimatedElements ref={ref}>
      <Computer visible={isVisible}>
        <ComputerBefore visible={isVisible}>
          <StaticImage
            placeholder="blurred"
            objectFit="cover"
            src="../../../images/monitor.png"
            alt="screen"
            style={{ width: "90%", height: "90%" }}
            imgStyle={{ objectFit: "contain", backdropFilter: "blur(30px)", width: "auto" }}
            quality={100}
          />
        </ComputerBefore>
        <ComputerAfter visible={isVisible}>
          <StaticImage
            placeholder="blurred"
            objectFit="cover"
            src="../../../images/monitor_after.png"
            alt="screen"
            style={{ width: "90%", height: "90%" }}
            imgStyle={{ objectFit: "contain", backdropFilter: "blur(30px)", width: "auto" }}
            quality={100}
          />
        </ComputerAfter>
      </Computer>
      <Laptop visible={isVisible}>
        <StaticImage
          placeholder="blurred"
          src="../../../images/rfid.png"
          alt="laptop"
          style={{ width: "90%", height: "90%" }}
          imgStyle={{ objectFit: "contain", backdropFilter: "blur(30px)", width: "auto" }}
          quality={100}
        />
      </Laptop>
      <Badge visible={isVisible}>
        <StaticImage
          placeholder="blurred"
          src="../../../images/band.png"
          alt="badge"
          style={{ width: "90%", height: "90%" }}
          imgStyle={{ objectFit: "contain" }}
          quality={100}
        />
      </Badge>
    </AnimatedElements>
  );
};

export default animatedImage;
