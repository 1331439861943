import styled from "styled-components";
import { Link } from "react-scroll";
import { Container } from "../../Styled";
import { Element } from "react-scroll";

const Styles = {
  ContentWrapper: styled(Element)`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 40vh;
    padddig: 20px 10% 20px 10%;
    @media (max-width: 1000px) {
      flex-direction: column;
      height: 60vh;
    }
  `,

  FotterColumn: styled.div`
    text-align: left;
    font-family: ${(props) => props.theme.font.normal};
    font-size: ${(props) => props.theme.fontSize.normal};
    align-self: center;
    @media (max-width: 1000px) {
      font-size: ${(props) => props.theme.fontSize.mobileDesc};
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  `,

  Links: styled(Container)`
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        @media (max-width: 1000px){
          align-items: center;
          width: 100%;
        }
    `,
    NavLink: styled(Link)`
        color: black;
        font-size: ${(props) => props.theme.fontSize.normal};
        font-style: normal;
        margin: 0;
        padding: 20px;
        transition: 0.3s;
        z-index: 50;
        font-weight: ${(props) => props.theme.fontWeight.normal};
        &:hover {
            cursor: pointer;
            color: ${(props) => props.theme.color.neavyBlue};
        }
        @media (max-width: 1000px) {
          font-size: ${(props) => props.theme.fontSize.mobileDesc};
          padding: 20px;
    `,
};

export default Styles;
