import React from "react";
import Styles from "./styles";
import { StaticImage } from "gatsby-plugin-image";

const { Wrapper } = Styles;

const ClimbingBoy = () => {
  return (
    <Wrapper>
      <StaticImage
        src="../../images/climbing.png"
        objectFit="contain"
        alt="park view"
        imgStyle={{ maxWidth: "100%", maxHeight: "100%"}}
        style={{ width: "100%", height: "100%"}}
        quality={100}
      />
    </Wrapper>
  );
};

export default ClimbingBoy;
