import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const SVGIcon = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  z-index: 50;
`;

export const Text = styled.p`
  color: black;
  font-size: ${(props) => props.theme.fontSize.normal};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-style: normal;
  margin: 0;
  padding: 10px;
`;
