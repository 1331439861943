import styled from "styled-components";

const Styles = {
  Wrapper: styled.div`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 125vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    background: url(/climbing.png);
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1000px) {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      height: 35vh;
    }
  `,
};

export default Styles;
