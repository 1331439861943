import styled from "styled-components";
import { Element } from "react-scroll";

export const AboutArea = styled(Element)`
  position: relative;
  height: 55vh;
  width: 100%;
  @media (max-width: 1000px) {
    height: 125vh;
    margin-top: 20vh;
  }
`;