import React from "react";
import Styles from "./styles";

const { Wrapper, Title } = Styles;

const SweetBoyTitle = () => {
  return (
    <Wrapper name="gallery">
      <Title>{"PRZYKŁADOWE ZASTOSOWANIE"}</Title>
    </Wrapper>
  );
};

export default SweetBoyTitle;
