import React, { useState, useContext, useEffect } from "react";
import ITextInput from "../TextInput/types";
import Styles from "./styles";
import { ContactContect } from "..";

const { TextArea, LabelWrapper, AreaWrapper, AreaLabel, Error } = Styles;

const AreaText = ({ placeholder, inputId, validator }: ITextInput) => {
  const { formErrors, setFormErrors } = useContext(ContactContect);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState("");
  const [valid, setValid] = useState(false);
  const [printError, setPrintError] = useState(false);
  const [timer, setTimer] = useState(null);
  const [validMessage, setValidMessage] = useState(validator.getErrorMessage());

  useEffect(() => {
    validInputValue();
  }, [value]);

  useEffect(() => {
    updateFormErrors();
  }, [valid]);

  const checkActive = () => {
    if (value === "") {
      setActive(false);
    }
  };

  const updateFormErrors = async () => {
    if (!valid && !formErrors.includes(inputId)) {
      console.log(valid + " " + inputId);
      await setFormErrors([...formErrors, inputId]);
    } else if (valid) {
      await setFormErrors(
        formErrors.filter((error: string) => error !== inputId)
      );
    }
  };

  const validInputValue = async () => {
    clearTimeout(timer);
    let validResult = validator.isAcceptable(value);
    let showError = false;
    setTimeout(() => {
      if (value && !validResult) {
        showError = true;
      } else {
        showError = false;
      }
      setPrintError(showError);
      setValid(validResult);
    }, 500);
  };

  return (
    <AreaWrapper>
      {active && (
        <LabelWrapper>
          <AreaLabel>{placeholder}</AreaLabel>
        </LabelWrapper>
      )}
      <TextArea
        onFocus={() => setActive(true)}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => checkActive()}
        placeholder={placeholder}
        typeof="text"
      />
      {printError && <Error>{validMessage}</Error>}
    </AreaWrapper>
  );
};

export default AreaText;
