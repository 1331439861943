import styled from "styled-components";
import { ColorKeyframeAnim } from "../colorKeyframes";
import { Element } from "react-scroll";

const Styles = {
  Wrapper: styled(Element)`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    gap: 10vw;
    background: url('/landingBackground.png');
    background-size: cover;

    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 10px;
    }
  `,
  

  TitleWrapper: styled.div`
    display: flex;
    align-self: center;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  `,

  H2Title: styled.h2`
    white-space: pre-line;
    font-size: ${(props) => props.theme.fontSize.big};
    color: ${(props) => props.theme.color.neavyBlue};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    animation: ${ColorKeyframeAnim} 20s infinite linear;
    @media (max-width: 1000px) {
      font-size: ${(props) => props.theme.fontSize.mobileTitle};
      width: 100%;
      flex-direction: column;
      white-space: normal;
    }
  `,
};

export default Styles;
