import React, { useEffect, useState } from "react";
import Styles from "./styles";
import { useIntl } from "gatsby-plugin-react-intl";
import { useInView } from 'react-intersection-observer';
const {
  Wrapper,
  Content,
  ImageWrapper,
  LeftPart,
  Astro,
  GameTitle,
} = Styles;
import { StaticImage } from "gatsby-plugin-image";

const AstroFlap = () => {
  const intl = useIntl();
  const [isVisible, setVisible] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
    <Wrapper>
      <Astro ref={ref}>
        <ImageWrapper href="https://oakgames.it/games/astroflap" visible={isVisible}>
          <StaticImage
            src="../../images/astro.png"
            objectFit="contain"
            alt="astro flap game preview"
            imgStyle={{ maxWidth: "100%" }}
            style={{ width: "100%" }}
            quality={100}
          />
        </ImageWrapper>
        <LeftPart visible={isVisible}>
          <GameTitle>ASTRO FLAP</GameTitle>
          <Content style={{ marginBottom: "15px" }}>
            {intl.formatMessage({ id: "astroFlapDescription" })}
          </Content>
        </LeftPart>
      </Astro>
    </Wrapper>
  );
};

export default AstroFlap;
