import React, { useEffect, useState } from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from 'react-intersection-observer';

import { InfoArea, Wrapper, H1Title, PDesc, Image } from "./styles";

const Info = () => {
  const intl = useIntl();
  const [isVisible, setVisible] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
      <InfoArea ref={ref}>
        <Image visible={isVisible}>
          <StaticImage
            placeholder="blurred"
            src="../../images/fun.png"
            alt="game devices"
            quality={100}
          />
        </Image>
        <Wrapper visible={isVisible}>
          <H1Title>{intl.formatMessage({ id: "aboutTitle" })}</H1Title>
          <PDesc>{intl.formatMessage({ id: "aboutDescription" })}</PDesc>
        </Wrapper>
      </InfoArea>
  );
};

export default Info;
