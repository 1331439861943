import styled, { css } from "styled-components";
import AnimationInterface from "./types";
import {
  GoingDown,
  GoingRight,
  GoingRightBadge,
  ComputerInsideAnim,
} from "./keyframes";

export const ComputerBefore = styled.div<AnimationInterface>`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ visible }) =>
  visible &&
  css`
    animation: ${ComputerInsideAnim} 1.5s 5.7s ease-out both;
    animation-direction: reverse;
  `}
`;

export const ComputerAfter = styled.div<AnimationInterface>`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ visible }) =>
    visible &&
    css`
      animation: ${ComputerInsideAnim} 1.5s 5.7s ease-out both;
    `}
`;

export const AnimatedElements = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: 1000px) {
    height: 50vh;
  }
`;

export const Computer = styled.div<AnimationInterface>`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 5%;
  left: 25%;
  right: 0;
  width: 70%;
  height: auto;
  transform-origin: center;
  @media (max-width: 1000px) {
    width: auto;
    height: 50vh;
    top: -10vh;
    left: 25%;
    right: 0;
    bottom: 0;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${GoingDown} 3s ease-out;
    `}
`;

export const Laptop = styled.div<AnimationInterface>`
  position: absolute;
  z-index: 4;
  top: 55%;
  bottom: 0;
  left: 10%;
  right: 0;
  width: 40%;
  height: auto;
  transform-origin: center;
  @media (max-width: 1000px) {
    width: auto;
    height: 30vh;
    top: 20vh;
    left: 10%;
    right: 0;
    bottom: 0;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${GoingRight} 3s ease-out;
    `}
`;

export const Badge = styled.div<AnimationInterface>`
  position: absolute;
  z-index: 5;
  top: 70%;
  bottom: 0;
  left: 0;
  right: 35%;
  width: 25%;
  height: auto;
  transform-origin: center;
  @media (max-width: 1000px) {
    width: auto;
    height: 15vh;
    top: 35vh;
    left: -25%;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${GoingRightBadge} 3s 3.2s ease-out both;
    `}
`;
