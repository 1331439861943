import styled from "styled-components";

const Styles = {
  Wrapper: styled.div`
    margin: 3vh 0;
    padding: 20px;
    flex-shrink: 0;
    flex-direction: column;
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(40px);
    border-radius: 4px;
    border: 2px solid white;
    width: 30%;
    @media (max-width: 1000px) {
      justify-content: center;
      margin-left: 0px;
      background: none;
      width: auto;
      border: none;
      backdrop-filter: none;
      background: none;
    }
  `,

  ButtonWrapper: styled.div`
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    color: white;
    @media (max-width: 1000px) {
      justify-content: center;
      margin-top: 20px;
    }
  `,

  LabelWrapper: styled.div`
    position: relative;
    width: 100%;
  `,

  InputLabel: styled.label`
    top: -9px;
    position: absolute;
    color: ${(props) => props.theme.color.violet};
    background-color: white;
    padding: 2px 5px 2px 5px;
    font-size: 12px;
  `,
};

export default Styles;
