import { keyframes } from "styled-components";


const ShowingUp = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

export { ShowingUp };
