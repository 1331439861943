import React, { useContext, useEffect, useState } from "react";
import Styles from "./styles";
import ITextInput from "./types";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ContactContect } from "..";

const {
  InputWrapper,
  TextInput,
  LabelWrapper,
  InputLabel,
  TickIcon,
  InputRow,
  Error,
} = Styles;

const InputText = ({ placeholder, inputId, validator }: ITextInput) => {
  const { formErrors, setFormErrors } = useContext(ContactContect);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState("");
  const [valid, setValid] = useState(false);
  const [printError, setPrintError] = useState(false);
  const [timer, setTimer] = useState(null);
  const [validMessage, setValidMessage] = useState(validator.getErrorMessage());

  useEffect(() => {
    validInputValue();
  }, [value]);

  useEffect(() => {
    updateFormErrors();
  }, [valid]);

  const checkActive = () => {
    if (value === "") {
      setActive(false);
    }
  };

  const updateFormErrors = async () => {
    if (!valid && !formErrors.includes(inputId)) {
      console.log(valid + " " + inputId);
      await setFormErrors([...formErrors, inputId]);
    } else if (valid) {
      await setFormErrors(
        formErrors.filter((error: string) => error !== inputId)
      );
    }
  };

  const validInputValue = async () => {
    clearTimeout(timer);
    let validResult = validator.isAcceptable(value);
    let showError = false;
    setTimeout(() => {
      if (value && !validResult) {
        showError = true;
      } else {
        showError = false;
      }
      setPrintError(showError);
      setValid(validResult);
    }, 500);
  };

  return (
    <InputWrapper>
      <InputRow>
        {active && (
          <LabelWrapper>
            <InputLabel>{placeholder}</InputLabel>
          </LabelWrapper>
        )}
        <TextInput
          onFocus={() => setActive(true)}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => checkActive()}
          placeholder={placeholder}
        />
        {active && valid && <TickIcon icon={faCheck} />}
      </InputRow>
      {printError && <Error>{validMessage}</Error>}
    </InputWrapper>
  );
};

export default InputText;
