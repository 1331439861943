import { keyframes } from "styled-components";


const GoingDown = keyframes`
    0%{
        transform: translateY(-100vh);
    }
    100%{
        transform: translateY(0);
    }
`;

const GoingRight = keyframes`
    0%{
        transform: translateX(-70vw);
    }
    100%{
        transform: translateX(0);
    }
`;

const ComputerInsideAnim = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

const GoingRightBadge = keyframes`
    0%{
        transform: translateX(-80vw);
    }
    80%{
        transform: translateX(3vw);
    }
    100%{
        transform: translateX(0);
    }
`;

export { GoingDown, GoingRight, GoingRightBadge, ComputerInsideAnim };
