import { StringValidator } from "./stringValidatiorIT";
import { useIntl } from "gatsby-plugin-react-intl";
export class PhoneValidation implements StringValidator {
  regex: RegExp = new RegExp(
    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
  );
  isAcceptable = (s: string) => {
    return this.regex.test(s) || s === "";
  };
  getErrorMessage = () => {
    const intl = useIntl();
    return intl.formatMessage({ id: "validPhone" });
  };
}
