import React from "react";
import Styles from "./styled";
import { useIntl } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

const { NavContainer, NavLink, Logo } = Styles;

const Navigation = () => {
  const intl = useIntl();
  return (
    <NavContainer>
      <Logo>
        <StaticImage
          imgStyle={{ objectFit: "scale-down", width: "75%" }}
          placeholder="blurred"
          src="../../../images/logo.png"
          alt="page logo"
          quality={100}
        />
      </Logo>
      <NavLink to="about" active style={{textDecoration: "underline"}} smooth={true} offset={50}>
        {intl.formatMessage({ id: "about" })}
      </NavLink>
      <NavLink to="gallery" smooth={true} offset={50}>
        {intl.formatMessage({ id: "gallery" })}
      </NavLink>
      <NavLink to="contact" smooth={true} offset={50}>
        {intl.formatMessage({ id: "contact" })}
      </NavLink>
    </NavContainer>
  );
};

export default Navigation;
