import React from "react";
import Styles from "./styles";

const { Wrapper, Btn } = Styles;

const Button = ({ children }) => {
  return (
    <Wrapper>
      <Btn type="submit">{children}</Btn>
    </Wrapper>
  );
};

export default Button;
