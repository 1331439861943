import styled, { css } from "styled-components";
import { ShowingUp } from "./keyframes";
import AnimationInterface from "./types";

export const About = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 2vw;
  padding: 11.5vh 0;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 5vh;
  }
`;

export const IconInfo1 = styled.div<AnimationInterface>`
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2vh;
  @media (max-width: 1000px) {
    align-items: center;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${ShowingUp} 1.5s linear 0.5s;
      animation-fill-mode: forwards;
    `}
`;

export const IconInfo2 = styled.div<AnimationInterface>`
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2vh;
  @media (max-width: 1000px) {
    align-items: center;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${ShowingUp} 1.5s linear 2s;
      animation-fill-mode: forwards;
    `}
`;

export const IconInfo3 = styled.div<AnimationInterface>`
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2vh;
  @media (max-width: 1000px) {
    align-items: center;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${ShowingUp} 1.5s linear 3.5s;
      animation-fill-mode: forwards;
    `}
`;

export const IconTitle = styled.div`
  font-size: ${(props) => props.theme.fontSize.normal};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: black;
  text-transform: uppercase;
  @media (max-width: 1000px) {
    font-size: ${(props) => props.theme.fontSize.mobileDesc};
  }
`;

export const IconDescription = styled.div`
  font-size: ${(props) => props.theme.fontSize.normal};
  width: 20vw;
  color: black;
  @media (max-width: 1000px) {
    width: 70%;
    font-size: ${(props) => props.theme.fontSize.mobileDesc};
    text-align: center;
  }
`;
