import React from "react";
import Styles from "./styles";
import { useIntl } from "gatsby-plugin-react-intl";

const { FotterColumn, ContentWrapper, Links, NavLink } = Styles;

const Footer = () => {
  const intl = useIntl();
  return (
    <ContentWrapper name="footer">
      <Links>
        <NavLink to="about" smooth={true} offset={50}>
          {intl.formatMessage({ id: "about" })}
        </NavLink>
        <NavLink to="gallery" smooth={true} offset={50}>
          {intl.formatMessage({ id: "gallery" })}
        </NavLink>
        <NavLink to="demo" smooth={true} offset={50}>
          {intl.formatMessage({ id: "demo" })}
        </NavLink>
      </Links>
      <FotterColumn>
        <div>
          MoveArc by Oakfusion 
          <br/>
          Żmigrodzka 244 
          <br/>
          51-131 Wrocław, Poland <br />
          <br />
          +48 692 520 453 office@oakfusion.pl
        </div>
      </FotterColumn>
    </ContentWrapper>
  );
};

export default Footer;
