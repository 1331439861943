import { StringValidator } from "./stringValidatiorIT";
import { useIntl } from "gatsby-plugin-react-intl";
export class MailValidation implements StringValidator {
  regex: RegExp = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  );
  isAcceptable = (s: string) => {
    return this.regex.test(s);
  };
  getErrorMessage = () => {
    const intl = useIntl();
    return intl.formatMessage({ id: "validEmail" });
  };
}
