import { keyframes } from "styled-components";

const GoingRight = keyframes`
    0%{
        transform: translateX(-100vw);
    }
    100%{
        transform: translateX(0);
    }
`;

const GoingLeft = keyframes`
    0%{
        transform: translateX(100vw);
    }
    100%{
        transform: translateX(0);
    }
`;

export { GoingLeft, GoingRight };
