import React, { useEffect, useState } from "react";
import Styles from "./styles";
import { useIntl } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from 'react-intersection-observer';

const {
  Wrapper,
  Content,
  Title,
  Incentive,
  ImageWrapper,
  LeftPart,
  Smoke,
  GameTitle,
} = Styles;

const SmokeFusion = () => {
  const intl = useIntl();
  const [isVisible, setVisible] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
    <Wrapper name="demo">
      <Title>{intl.formatMessage({ id: "testOurGameTitle" })}</Title>
      <Content padding={true}>{intl.formatMessage({ id: "testOurGameDescription" })}</Content>
      <Smoke ref={ref}>
        <LeftPart visible={isVisible}>
          <GameTitle>SMOKE FUSION</GameTitle>
          <Content padding={false} style={{ marginBottom: "15px" }}>
            Smoke Fusion to gra, która odbywa się na interaktywnych ekranach
            rozmieszonych na terenie parku. Sprawi, że Twój park rozrywki będzie
            przyjemniejszy dla dzieci, a jednocześnie zachęci je do większej
            aktywności. Idealna gra dla dzieci w każdym wieku. Jest świetnym
            sposobem na wydłużenie czasu spędzanego przez odwiedzających w
            parku. Pozwól im przeżyć zupełnie nowy rodzaj przygody i rzuć
            wyzwanie dzięki naszej aplikacji!
          </Content>
          <Incentive>
            Wypróbuj grę i przeżyj ją jako osoba przebywająca w parku
          </Incentive>
        </LeftPart>
        <ImageWrapper
          href="https://oakgames.it/games/smokefusion"
          visible={isVisible}
        >
          <StaticImage
            src="../../images/smoke.png"
            objectFit="contain"
            alt="astro flap game preview"
            imgStyle={{ maxWidth: "100%" }}
            style={{ width: "100%" }}
            quality={100}
          />
        </ImageWrapper>
      </Smoke>
    </Wrapper>
  );
};

export default SmokeFusion;
