import styled, { css } from "styled-components";
import AnimationInterface from "./types";
import { GoingLeft, GoingRight } from "./keyframes";

export const Wrapper = styled.div<AnimationInterface>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding: 0 5vw 1vh 0;
  transform: translateX(100vw);
  @media (max-width: 1000px) {
    align-items: center;
    padding: 0 0 1vh 0;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${GoingLeft} 2s ease-out 5s;
      animation-fill-mode: forwards;
      @media (max-width: 1000px) {
        animation-delay: 2s;
      }
    `}
`;

export const H1Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.smallerTitle};
  text-transform: uppercase;
  font-family: ${(props) => props.theme.font.main};
  color: ${(props) => props.theme.color.neavyBlue};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-bottom: 2vh;
  margin-top: 0;
  @media (max-width: 1000px) {
    font-size: ${(props) => props.theme.fontSize.mobileTitle};
  }
`;

export const PDesc = styled.p`
  color: black;
  font-size: ${(props) => props.theme.fontSize.normal};
  font-family: ${(props) => props.theme.font.main};
  width: 100%;
  @media (max-width: 1000px) {
    width: 80%;
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.mobileDesc};
  }
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  height: 70vh;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    height: 80vh;
  }
`;

export const Image = styled.div<AnimationInterface>`
  width: 100%;
  position: relative;
  left: -1%;
  transform: translateX(-100vw);
  @media (max-width: 1000px) {
    margin-bottom: 10vh;
  }
  ${({ visible }) =>
    visible &&
    css`
      animation: ${GoingRight} 2s ease-out;
      animation-delay: 7s;
      animation-fill-mode: forwards;
      @media (max-width: 1000px) {
        animation-delay: 0s;
      }
    `}
`;
