import styled from "styled-components";
import { myTheme } from "../../../MyTheme";

const {
  color: { neavyBlue },
} = myTheme;

const Styles = {
  Wrapper: styled.div`
    display: flex;
    position: relative;
    margin-top: 20px;
    width: 100%;
    justify-content: flex-end;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  `,

  Btn: styled.button`
    position: relative;
    padding: 19px 40px 19px 40px;
    color: white;
    background-color: ${neavyBlue};
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 18px;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.color.neavyBlue};
    }
    &:active {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4) 0 0);
    }
  `,
};

export default Styles;
