import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import Icons from "./Icons/icons";

import { AboutArea } from "./styled";

const About = () => {
  const intl = useIntl();
  return (
    <AboutArea name="about">
      <Icons />
    </AboutArea>
  );
};

export default About;
