import React from "react";
import Styles from "./styles";

const { Image } = Styles;

const SweetBoyPicture = () => {

  return (
    <Image/>
  );
};

export default SweetBoyPicture;
