import styled from "styled-components";

const Styles = {
  TextArea: styled.textarea`
    resize: vertical;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid black;
    outline: none;
    min-height: 28px;
    width: 100%;
    font-family: ${(props) => props.theme.font.fontInputs};
    font-size: ${(props) => props.theme.fontSize.normal};
    background-color: transparent;
  `,
  LabelWrapper: styled.div`
    position: relative;
    width: auto;
    height: 0px;
  `,
  AreaWrapper: styled.div`
    margin-top: 45px;
    /* display: flex; */
  `,

  AreaLabel: styled.label`
    top: -40px;
    position: absolute;
    margin-bottom: 20px;
    color: black;
    background-color: none;
    font-size: ${(props) => props.theme.fontSize.label};
    white-space: pre-line;
  `,
  Error: styled.div`
    margin-top: 2px;
    margin-top: 10px;
    height: 18px;
    color: ${(props) => props.theme.color.error};
    font-size: ${(props) => props.theme.fontSize.errors};
  `,
};

export default Styles;
