import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import { GoingLeft, GoingRight } from "./keyframes";
import AnimationInterface from "./types";
import ContentInterface from "./typesContent";

const Styles = {
  Wrapper: styled(Element)`
    justify-content: center;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 100px 0;
    width: 100%;
    @media (max-width: 1000px) {
      padding: 20px 0 50px 0;
    }
  `,

  Incentive: styled.div`
    white-space: pre-line;
    font-size: ${(props) => props.theme.fontSize.normal};
    font-family: ${(props) => props.theme.font.main};
    font-weight: bold;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
      margin-left: 50px;
      margin-right: 50px;
      text-align: center;
      justify-content: center;
    }
  `,

  Smoke: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  `,

  LeftPart: styled.div<AnimationInterface>`
    display: flex;
    flex-direction: column;
    width: 55vw;
    padding-left: 5vw;
    transform: translateX(-100vw);
    @media (max-width: 1000px) {
      width: 100%;
      padding-left: 0;
    }

    ${({ visible }) =>
      visible &&
      css`
      animation: ${GoingRight} 2s ease-out;
      animation-fill-mode: forwards;
      @media (max-width: 1000px){
        animation-delay: 2s;
      }
  `}
  `,

  Content: styled.div<ContentInterface>`
    align-self: flex-start;
    white-space: pre-line;
    font-size: ${(props) => props.theme.fontSize.normal};
    font-family: ${(props) => props.theme.font.main};
    margin-bottom: 15vh;
    padding-left: ${(props) => props.padding ? "5vw" : "0"};
    @media (max-width: 1000px) {
      margin-left: 50px;
      margin-right: 50px;
      margin-bottom: 10vh;
      padding-left: 0;
      text-align: center;
      justify-content: center;
      font-size: ${(props) => props.theme.fontSize.mobileDesc};
    }
  `,

  GameTitle: styled.div`
    align-self: flex-start;
    white-space: pre-line;
    font-size: ${(props) => props.theme.fontSize.mobileTitle};
    font-family: ${(props) => props.theme.font.main};
    margin-bottom: 30px;
    font-weight: bold;
    color: ${(props) => props.theme.color.neavyBlue};
    @media (max-width: 1000px) {
      align-self: center;
    }
  `,

  Title: styled.div`
    white-space: pre-line;
    text-transform: uppercase;
    align-self: flex-start;
    font-size: ${(props) => props.theme.fontSize.smallerTitle};
    font-family: ${(props) => props.theme.font.main};
    color: ${(props) => props.theme.color.neavyBlue};
    margin-bottom: 20px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    padding-left: 5vw;
    @media (max-width: 1000px) {
      align-self: center;
      padding-left: 0;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.mobileTitle};
      margin-top: 50px;
    }
  `,

  AnimatedElements: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: 100%;
  `,

  ImageWrapper: styled.a<AnimationInterface>`
    width: 90%;
    margin: 0 auto 2em;
    background: none;
    border: none;
    height: 20vh;
    transform: translateX(100vw);
    @media (max-width: 100px) {
      width: 50vw;
      height: auto;
      margin: 0;
    }

    ${({ visible }) =>
      visible &&
      css`
    animation: ${GoingLeft} 2s ease-out;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    @media (max-width: 1000px){
      animation-delay: 0s;
    }
  `}
  `,
};

export default Styles;
