import { keyframes } from "styled-components";

const ColorKeyframeAnim = keyframes`
    0%{
        color: #2535C6;
    }
    25%{
        color: #9EFCFF;
    }
    50%{
        color:  #FFF9AC;
    }
    75%{
        color: #FFC7EB;
    }
    100%{
        color: #2535C6;
    }
`;

export { ColorKeyframeAnim };
