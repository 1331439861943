import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "react-scroll";
import { Container } from "../../../Styled";
import { myTheme } from "../../../MyTheme";

const {
  color: { neavyBlue },
} = myTheme;

const Styles = {
    NavContainer: styled(Container)`
        height: 15vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1000px) {
            display: none;
        }
    `,
    NavLink: styled(Link)`
        color: black;
        font-size: ${(props) => props.theme.fontSize.normal};
        font-style: normal;
        font-weight: bold;
        color: ${({ active }) => (active ? neavyBlue : "black")};
        margin: 0;
        padding: 30px;
        transition: 0.3s;
        z-index: 50;
        &:hover {
            cursor: pointer;
            color: ${neavyBlue};
        }
    `,
    Logo: styled.div`
        position: absolute;
        left: 5%;
    `,
    LangBtn: styled(GatsbyLink)`
        position: relative;
        margin: 15px 0 10px 0;
        font-size: ${(props) => props.theme.fontSize.normal};
        transition: 0.3s;
        color: black;
        text-decoration: none;
        &:hover {
            color: white;
        }
    `,
    LangBtnWrapper: styled.div`
        margin-left: 30px;
        position: relative;
        color: black;
        z-index: 50;
    `,
};

export default Styles;
