import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import AnimatedImage from "./AnimatedImage/animatedImage";
import Navigation from "../Layout/Navigation";

import {
  LandingArea,
  TextSectionWrapper,
  H1Title,
  PDesc,
  H2SubTitle,
  Arrow,
  TextFlexbox,
  MobilePart,
  ComputerPart,
  Background,
} from "./styled";

const Landing = ({ref}) => {
  const intl = useIntl();
  return (
    <Background>
      <Navigation />
      <LandingArea ref={ref}>
        <TextFlexbox>
          <TextSectionWrapper>
            <H2SubTitle>{intl.formatMessage({ id: "subtitle1" })}</H2SubTitle>
            <H1Title>{intl.formatMessage({ id: "title1" })}</H1Title>
            <ComputerPart>
              <PDesc>{intl.formatMessage({ id: "description1" })}</PDesc>
            </ComputerPart>
          </TextSectionWrapper>
          <Arrow to="footer" smooth={true} offset={50}>
            <StaticImage
              placeholder="blurred"
              objectFit="scale-down"
              src="../../images/arrowDown.png"
              alt="double down arrow"
              quality={100}
            />
            <PDesc style={{ fontSize: "16px", color: "black", fontWeight: "400" }}>
              {intl.formatMessage({ id: "slide" })}
            </PDesc>
          </Arrow>
        </TextFlexbox>
        <AnimatedImage />
        <MobilePart>
          <PDesc>{intl.formatMessage({ id: "description1" })}</PDesc>
        </MobilePart>
      </LandingArea>
    </Background>
  );
};

export default Landing;
