import React from "react";
import Styles from "./styles";
import InputArea from "./InputArea";
import { useIntl } from "gatsby-plugin-react-intl";

const { Wrapper, TitleWrapper, H2Title } = Styles;

const Contact = () => {
  const intl = useIntl();

  return (
    <Wrapper name="contact">
      <TitleWrapper>
        <H2Title>{intl.formatMessage({ id: "contactUs" })}</H2Title>
      </TitleWrapper>
      <InputArea />
    </Wrapper>
  );
};

export default Contact;
