import styled from "styled-components";
import { Link } from "react-scroll";
import { FloatTextAnim } from "./keyframes";
import { ColorKeyframeAnim } from "../colorKeyframes";
import { myTheme } from "../../MyTheme";

const {
  color: { neavyBlue },
} = myTheme;

export const TextSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 20;
`;

export const MobilePart = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 25vh;
  }
`;

export const ComputerPart = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Arrow = styled(Link)`
  display: flex;
  flex-direction: column;
  z-index: 20;
  padding-top: 10vh;
  transition: 0.5s;
  animation: ${FloatTextAnim} 3s infinite ease-in-out;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const TextFlexbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 5vw;
  @media (max-width: 1000px) {
    padding-top: 8vh;
    align-self: flex-start;
  }
`;

export const H1Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.title};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: 2vh;
  margin-top: 0;
  align-self: flex-start;
  animation: ${ColorKeyframeAnim} 20s infinite linear;
  @media (max-width: 1000px) {
    font-size: ${(props) => props.theme.fontSize.mobileTitle};
    text-align: left;
    margin-bottom: 15vh;
  }
`;

export const H2SubTitle = styled.h2`
  font-size: ${(props) => props.theme.fontSize.normal};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: 0;
  align-self: flex-start;
  text-align: left;
  color: ${neavyBlue};
  @media (max-width: 1000px) {
    font-size: ${(props) => props.theme.fontSize.small};
    margin: 1vh 5vw 0 0;
  }
`;

export const PDesc = styled.p`
  color: black;
  font-size: ${(props) => props.theme.fontSize.normal};
  @media (max-width: 1000px) {
    width: 80%;
    font-size: ${(props) => props.theme.fontSize.mobileDesc};
    margin-top: 30vh;
  }
`;

export const LandingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 85vh;
  width: 100%;
  align-items: center;
  @media (max-width: 1000px) {
    height: 100vh;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
`;

export const Background = styled.div`
  background: url("/landingBackground.png");
  background-size: cover;
  width: 100%;
`;
