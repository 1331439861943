import styled from "styled-components";
import { Element } from "react-scroll";

const Styles = {
  Wrapper: styled(Element)`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    @media (max-width: 1000px) {
      margin-top: 20px;
      margin-bottom: 40px;
      width: auto;
    }
  `,

  Title: styled.div`
    text-align: center;
    white-space: pre-line;
    font-size: ${(props) => props.theme.fontSize.title};
    font-family: ${(props) => props.theme.font.main};
    color: ${(props) => props.theme.color.neavyBlue};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    @media (max-width: 1000px) {
      font-size: ${(props) => props.theme.fontSize.mobileTitle};
      margin-bottom: 10px;
    }
  `,
};

export default Styles;
