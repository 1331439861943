import React, { useState, createContext, useEffect } from "react";
import Styles from "./styles";
import TextInput from "./TextInput";
import Button from "../../Utils/Button";
import AreaText from "./TextArea";
import { LengthValidation } from "./Errors/lengthValidator";

import { MailValidation } from "./Errors/mailValidation";
import { PhoneValidation } from "./Errors/phoneValidation";
import { useIntl } from "gatsby-plugin-react-intl";

const { Wrapper, ButtonWrapper } = Styles;

export const ContactContect = createContext(null);

const InputArea = () => {
  const inputsIds = ["name", "companyName", "email", "phoneNumber"];
  const intl = useIntl();
  const [formErrors, setFormErrors] = useState(inputsIds);

  const isValid = () => {
    return formErrors.length === 0;
  };

  const handleSubmit = (e) => {
    if (!isValid) {
      // todo add send message feature
    } else {
      e.preventDefault();
      return false;
    }
  };

  return (
    <ContactContect.Provider
      value={{
        formErrors,
        setFormErrors,
      }}
    >
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <TextInput
            placeholder={intl.formatMessage({ id: "name" })}
            inputId={inputsIds[0]}
            validator={new LengthValidation(3)}
          />
          <TextInput
            placeholder={intl.formatMessage({ id: "company" })}
            inputId={inputsIds[1]}
            validator={new LengthValidation(3)}
          />
          <TextInput
            placeholder={intl.formatMessage({ id: "mail" })}
            inputId={inputsIds[2]}
            validator={new MailValidation()}
          />
          <TextInput
            placeholder={intl.formatMessage({ id: "phone" })}
            inputId={inputsIds[3]}
            validator={new PhoneValidation()}
          />
          <AreaText
            placeholder={intl.formatMessage({ id: "messageToSend" })}
            inputId={inputsIds[4]}
            validator={new LengthValidation(3)}
          />
          <ButtonWrapper>
            <Button children={intl.formatMessage({ id: "send" })} />
          </ButtonWrapper>
        </form>
      </Wrapper>
    </ContactContect.Provider>
  );
};

export default InputArea;
