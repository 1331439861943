import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import { useInView } from 'react-intersection-observer';

import { About, IconInfo1, IconInfo2, IconInfo3, IconTitle, IconDescription } from "./styles";

const Icons = () => {
  const intl = useIntl();
  const [isVisible, setVisible] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
    <About>
      <IconInfo1 visible={isVisible} ref={ref}>
        <StaticImage
          imgStyle={{ objectFit: "scale-down", width: "75%" }}
          placeholder="blurred"
          src="../../../images/Info/two-players.png"
          alt="first info icon"
          quality={100}
        />
        <IconTitle>{intl.formatMessage({ id: "card1Title" })}</IconTitle>
        <IconDescription>
          {intl.formatMessage({ id: "card1Description" })}
        </IconDescription>
      </IconInfo1>
      <IconInfo2 visible={isVisible}>
        <StaticImage
          imgStyle={{ objectFit: "scale-down" }}
          placeholder="blurred"          
          src="../../../images/Info/rock-star.png"
          alt="second info icon"
          quality={100}
        />
        <IconTitle>{intl.formatMessage({ id: "card2Title" })}</IconTitle>
        <IconDescription>
          {intl.formatMessage({ id: "card2Description" })}
        </IconDescription>
      </IconInfo2>
      <IconInfo3 visible={isVisible}>
        <StaticImage
          imgStyle={{ objectFit: "scale-down" }}
          placeholder="blurred"
          src="../../../images/Info/clock.png"
          alt="third info icon"
          quality={100}
        />
        <IconTitle>{intl.formatMessage({ id: "card3Title" })}</IconTitle>
        <IconDescription>
          {intl.formatMessage({ id: "card2Description" })}
        </IconDescription>
      </IconInfo3>
    </About>
  );
};

export default Icons;
