import { StringValidator } from "./stringValidatiorIT";
import { useIntl } from "gatsby-plugin-react-intl";
export class LengthValidation implements StringValidator {
  min: number;
  max: number;
  toLong: boolean = false;
  constructor(min: number);
  constructor(min: number, max: number);
  constructor(min: number, max?: number) {
    this.min = min;
    this.max = max ?? 0;
  }

  isAcceptable = (s: string) => {
    this.toLong = this.max !== 0 && this.max < s.length;
    return this.min <= s.length && !this.toLong;
  };

  getErrorMessage = () => {
    const intl = useIntl();
    return this.toLong
      ? intl.formatMessage({ id: "validToLong" }) + " " + this.max
      : intl.formatMessage({ id: "validToShort" }) + " " + this.min;
  };
}
